<template>
  <div class="full-page-wrapper">

    <!-- Nav -->
    <nav-bar class="container" />

    <!-- Hero -->
    <section class="outer-container">
      <div class="hero-img-wrapper">
        <img class="hero-img" src="https://images.unsplash.com/photo-1468495244123-6c6c332eeece?ixlib=rb-1.2.1&auto=format&fit=crop&w=1222&q=80" alt="Choices">
      </div>
    </section>

    <!-- Article content -->
    <section class="container article-wrapper">
      <div class="row">
        <article class="col-12 article-content">
          <h1 class="big-title">From Team Lead to Digital Nomad :: Taking my biggest decision yet.</h1>

          <h2 class="regular-title">
            <div class="tag">Digital Nomad</div>
            <div class="tag">Career</div>
            <div class="tag">Goals</div>
            <div class="tag">Dreams</div>
            <div class="tag">Passion</div>
          </h2>

          <p class="regular-text">January 2020 : 30 y/o Web developper. I was starting my 4th year in a Montréal, Qc, Web Agency - <a href="#">byhoffman.com</a> and my 2nd year as the Director Of Technology. I was in charge of the R&D and P.O.C. I was leading a team of 4 developpers and in charge of their personal growth "Roadmap". I was living the dream. But yet, something was missing.</p>

          <img class="article-img" src="https://images.unsplash.com/photo-1528181304800-259b08848526?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" alt="Thailand">

          <p class="regular-text">I was just getting back from a 1 month trip to Thailand where I had the chance to meet awesome people, fellow programmers / Digital Nomads and enjoy a lifestyle that was just 100% [Fun].</p>

          <p class="regular-text">When I came back, the routine hit me hard. I was enjoying everything I had, don't get me wrong. But I was slowly getting stuck into my comfort-zone. My mind was constantly going back to the Temples, coffee shop & Coworking Spaces of Chiang Mai.</p>

          <blockquote>
            <p class="regular-text">Sometimes, even if you really know what you want, choices aren't always easy to make.</p>
          </blockquote>

          <img class="article-img" src="https://images.unsplash.com/photo-1527192491265-7e15c55b1ed2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80" alt="Workspace">

          <p class="regular-text">Leaving beahind so much. The best [Superiors] you could think of, a Team of 4 friends more than employee, a role that is super [gratifiant]. All those ingredients made that decision super hard.</p>

          <aside>
            <h4 class="medium-title">So how do you make that kind of decision?</h4>
            <p class="regular-text">Think of HR software as a second step. First, you need a strategy. Identify your stakeholders, then determine your budget, timeline, and the overarching problem you’re trying to solve. For example, you might need an HRIS to:</p>
            <ul class="regular-list">
              <li>Writing down your thoughts</li>
              <li>Making a recap of your last 10 years</li>
              <li>Makeing a list of people you know that can help you with that transition</li>
              <li>Taking a leap of faith</li>
              <li>Calling you parents just to get their approval 😂</li>
            </ul>
            <p class="regular-text">But seriously, those were the steps that helped me have the courage to make that final decision</p>
          </aside>

          <p class="regular-text">So here I was now, departing for a Digital Nomad life style and filled with passion, joy ...and fears. (But in a good way 💪)</p>

          <!-- <h3 class="medium-title">3 key questions to ask when evaluating HR software</h3>
          <ol class="regular-list">
            <li><strong>How long will it take to implement from the minute we sign the contract to the minute we go live?</strong>
            “I wasn’t given the luxury of a leisurely implementation,” says Margaux. Ask your vendor what kind of data they will require, if they have clear implementation processes, and how they’ll make this part as easy as possible so you can go live.</li>
            <li><strong>What exactly will my employees see?</strong>
            “I watched a few videos to make sure I was fully prepared for any questions from my employees,” said Margaux. If the HR software isn’t easy and intuitive for your employees, you’ll spend just as much time answering questions as you did managing paper forms.</li>
            <li><strong>What makes you feel confident in your ability to protect my data?</strong>
            “Who hosts your cloud database? What is the backup and redundancy process? I’ve asked for references in the past. In some companies, this would need to be vetted by IT.”</li>
          </ol> -->

          <img class="article-img" src="https://images.unsplash.com/photo-1549414279-d6799fcf2279?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80" alt="Digital Nomad, Follow that dream">
        </article>
      </div>
    </section>

    <!-- Comments -->
    <!-- <div class="container comments-wrapper">
      <div class="row">
        <div class="col-12 comments">
          <vue-disqus title="Article Title" shortname="vincbour" :identifier="'article'" :url="'http://localhost:8081/blog/article'"></vue-disqus>
        </div>
      </div>
    </div> -->

    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

    <!-- Hero -->
    <section class="outer-container">
      <div class="hero-img-wrapper">
        <img class="hero-img" src="https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" alt="Progressive Web App versus Native App">
      </div>
    </section>

    <!-- Article content -->
    <section class="container article-wrapper">
      <div class="row">
        <article class="col-12 article-content">
          <h1 class="big-title">Progressive Web App versus Native App</h1>

          <h2 class="regular-title">
            <div class="tag">PWA</div>
            <div class="tag">Web application</div>
            <div class="tag">Technology</div>
            <div class="tag">Development</div>
          </h2>

          <h4 class="medium-title">What is a PWA ?</h4>

          <p class="regular-text">Progressive Web Apps are a great alternative to Native App developement. They can (most of the time) provide the same result in terms of functionalities and performance. They are essentially an 'Enchance' version of a Website.<p>

          <p class="regular-text">Simillar to a Native App, a PWA can be installed on the user's device to be easely accessed. On top of that, they have some extra capabilities in terms of things they can access/perform.</p>

          <h4 class="medium-title">What Can a PWA do more that an Website ?</h4>

          <img class="article-img" src="https://images.unsplash.com/photo-1531730194970-f4d4bfd6475d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1353&q=80" alt="What Can a PWA do more that an Website ?">

          <p class="regular-text">Having a Progressive Web App, you can have access to advance Web functionalities such as Working Offline, Push Notifications and some hardware access that you can't have normally. (camera, GPS, microphone, user's contact list, and more)</p>

          <h4 class="medium-title">What are the benefits of PWAs</h4>

          <img class="article-img" src="https://images.unsplash.com/photo-1459257831348-f0cdd359235f?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80" alt="What are the benefits of PWAs">

          <p class="regular-text">The biggest benefit is by far the ease of Installation and Update. When building a Native App, you have to take in consideration the specific platforms it will target whereas with a PWA, you can build ONCE and publish anywhere.</p>

          <p class="regular-text">In fact, with 1 Progressive Web App, and a good responsive layout, you can launch a working project on a Desktop, an iPad, any Mobile device and even something larger like a Smart TV. And the cherry on top of the cake is that, if you want to make any update, be it a text, feature or layout update, you just have to deploy it one and EVERYONE will automatically have access to it WIHTOUT the need to manually update it. It will take less time for developpers to develop and will cost you less money.</p>

          <p class="regular-text">To be PWA compliant, you need to adheer to certain standard. Such as <a href="#">Web performances</a>. A Progressive Web app is by defenition a Website that is Fast, light and secure.</p>

          <p class="regular-text">If the apps stores precense is important for you, it is still possible. Althoug you won't need access to any Store (App Store / Google play store) to prompt a user for the installation, you will still be able to publish a 'Native' wrapper (which in terms is just a webview of you app) to any store you want.</p>

          <h4 class="medium-title">Use case</h4>

          <p class="regular-text">With all those benefits, it is fair to say that PWAs are a perfect candidate to <a href="#">build any MVP</a> of any product. If you want to test the market wuthout investing too much money, you solution is definitely met with a Progressive Web App. It will require less time for developper to create than a native app.</p>

          <blockquote>
            <p class="regular-text">For any huge and hard to maintain Excel sheet out there, ...there is a use case for a PWA</p>
          </blockquote>

          <img class="article-img" src="https://images.unsplash.com/photo-1529078155058-5d716f45d604?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1349&q=80" alt="Excel (.xlsx) sheet with some calculations">

          <p class="regular-text">If you have any process in your campany that you feel could be automated and made easier, there is definitly a fit and PWA can help you make your life easier. That process could be an Excel (.xlsx) sheet with some calculations that you need to manually maintain or any manual process that could be organise and automated with a User friendly user interce and lot of quickly accessible Stats to keep track of everything... Thoses are the best use case for a PWA.</p>

          <p class="regular-text">If you find yourself searching High and Low for a service or a 'Ready-made' paltform to take care of your needs but can't really find anyrhing perfect (either doing less or too much of what you ask), a custom progressive Web app tailored to your needs could be a perfect fit.</p>

          <aside>
            <h4 class="medium-title">Real world exemple and success stories</h4>

            <ul class="regular-list">
              <li>Tinder has <a href="https://medium.com/@addyosmani/a-tinder-progressive-web-app-performance-case-study-78919d98ece0" target="_blank" rel="noopened noreferer">reduced page loading time from 11.9 to 4.69 seconds</a>. The Tinder’s progressive app is 90% “lighter” than its native app.</li>
              <li>Uber PWA takes almost nothing in memory and <a href="https://eng.uber.com/m-uber/" target="_blank" rel="noopened noreferer">loads in 3 seconds even via a 2G Internet connection</a>.</li>
              <li>Forbes has <a href="https://digiday.com/media/new-mobile-site-forbes-boosted-impressions-per-session-10-percent/" target="_blank" rel="noopened noreferer">decreased page loading time from 6.5 (for its previous site) to 2.5 seconds and increased the first users’ impressions by 10%</a>.</li>
            </ul>

            <p class="regular-text">Those are just a small portion of the big companies that used the power of PWAs to acheive their goals.</p>

          </aside>

          <h4 class="medium-title">In conclusion</h4>

          <p class="regular-text">You should definitly take in consideration a Progressive Web App over a Native app. Most of the time, it will do 90% of what you want and will make sure there is a market before building the full fledge application.</p>

          <p class="regular-text">It can also, onits own, be exactly what you need in terms of custom SASS and will cost you way less than a Native app.</p>

          <p class="regular-text">If you want to know more about PWA ro are interested in building one, feel free to <a href="#" @click.prevent="openContactForm">contact me</a>.</p>
        </article>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

export default {
  head() {
    return {
      title: {
        inner: 'Modern Web Expert | Development, Consulting & Design'
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `${this.appTitle}` },
        {
          name: 'description',
          content: `${
            this.appTitle
          } Digital Nomad, Freelance, Web Apps & Websites Developper.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `${this.appTitle}` },
        {
          n: 'twitter:description',
          c: `${
            this.appTitle
          } Digital Nomad, Freelance, Web Apps & Websites Developper.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:site_name', content: `${this.appTitle}` },
        {
          property: 'og:description',
          content: `${
            this.appTitle
          } Digital Nomad, Freelance, Web Apps & Websites Developper.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        }
      ]
    }
  },
  components: { NavBar, Footer },
  computed: mapState('app', ['appTitle']),
  methods: {
    openContactForm() {
      this.$eventHub.$emit('open-contact-form')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.full-page-wrapper {
  position: relative;
  z-index: 2;
  min-height: 100vh;
  padding-bottom: 9rem;
  background: #fff;
  border-top: 0.5rem solid $accent2-color;

  @media only screen and (min-width: $breakpoint-small) {
    padding-bottom: 25rem;
    border-top: 3rem solid $accent2-color;
  }

  .outer-container {
    position: relative;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    .hero-img {
      display: block;
      width: 100%;
    }
  }

  .hero-img-wrapper {
    position: relative;
    width: 100%;
    height: 30vw;
    min-height: 250px;
    max-height: 600px;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      display: block;
      width: 100%;
    }
  }
}

.article-wrapper {
  position: relative;
  z-index: 3;
  min-height: 100vh;
  padding: 1rem 1rem;
  background: #fff;
  transform: translate3d(0, -5rem, 0);

  @media only screen and (min-width: $breakpoint-small) {
    padding: 2rem 2rem;
  }

  @media only screen and (min-width: $breakpoint-large) {
    padding: 2rem 4rem;
  }

  // Article Typography + Blocks components

  p a {
    color: $accent-color;
  }

  .big-title {
    font-family: $font-family-regular;
    margin: 0;
    padding: 1rem 0 1rem 0;
    font-size: 1.345rem;
    line-height: 1.3;
    font-weight: 700;

    @media only screen and (min-width: $breakpoint-small) {
      padding: 3rem 0 2rem 0;
      font-size: 1.65rem;
    }

    @media only screen and (min-width: $breakpoint-large) {
      font-size: 2.5rem;
    }

    strong {
      color: $accent-color;
    }

    span {
      color: $light-grey;
    }
  }

  .medium-title {
    font-family: $font-family-regular;
    margin: 0;
    padding: 3rem 0 2rem 0;
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 700;

    @media only screen and (min-width: $breakpoint-small) {
      padding: 3rem 0 2rem 0;
      font-size: 1.65rem;
    }

    @media only screen and (min-width: $breakpoint-large) {
      font-size: 2rem;
    }

    strong {
      color: $accent-color;
    }

    span {
      color: $light-grey;
    }
  }

  .regular-title {
    font-family: $font-family-regular;
    margin: 0;
    padding: 0 0 2rem 0;
    font-size: 1rem;
    line-height: 1;
    font-weight: 400;
    color: $light-read;

    @media only screen and (min-width: $breakpoint-small) {
      font-size: 1.35rem;
    }

    @media only screen and (min-width: $breakpoint-large) {
      font-size: 1.35rem;
    }

    strong {
      color: $accent-color;
    }

    span {
      color: $light-read;
    }

    a {
      color: $accent-color;
    }
  }

  p,
  .regular-text {
    margin: 0;
    padding: 0.5rem 0;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;

    @media only screen and (min-width: $breakpoint-small) {
      font-size: 1.175rem;
    }

    @media only screen and (min-width: $breakpoint-large) {
      font-size: 1.175rem;
    }

    strong {
      color: $accent-color;
    }

    span {
      color: $light-grey;
    }

    a {
      color: $accent-color;
    }
  }

  ul,
  .regular-list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 2rem;
    font-size: 1rem;
    line-height: 1;
    font-weight: 400;

    @media only screen and (min-width: $breakpoint-small) {
      padding-left: 5rem;
      font-size: 1.175rem;
    }

    @media only screen and (min-width: $breakpoint-large) {
      padding-left: 5rem;
    }

    li {
      padding: 0.5rem 0;

      a {
        color: $accent-color;
      }
    }

    strong {
      display: block;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }
  }

  blockquote {
    margin: 2rem 0;
    max-width: 570px;

    @media only screen and (min-width: $breakpoint-small) {
      margin: 4rem 0;
    }

    p,
    .regular-text {
      color: $accent-color;
      font-size: 1.245rem;
      font-family: $font-family-heavy;
      font-weight: 400;

      @media only screen and (min-width: $breakpoint-small) {
        font-size: 1.65rem;
      }

      @media only screen and (min-width: $breakpoint-large) {
        font-size: 2rem;
      }

      &:before {
        content: "";
        background-color: $accent-color;
        display: block;
        height: 2px;
        margin-bottom: 1.8rem;
        width: 60px;
      }

      a {
        color: $accent-color;
      }
    }
  }

  aside {
    background-color: #F0F4FF;
    border-left: 0.25rem solid #3E69E9;
    margin: 2rem 0;
    padding: 1rem 1rem;

    @media only screen and (min-width: $breakpoint-small) {
      margin: 4rem 0;
      padding: 2rem 4rem;
      border-left: 0.5rem solid #3E69E9;
    }

    @media only screen and (min-width: $breakpoint-large) {
      margin: 4rem 0;
      padding: 2rem 4rem;
    }
  }

  img,
  .article-img {
    display: block;
    width: 100%;
    margin: 2rem 0;

    @media only screen and (min-width: $breakpoint-small) {
      margin: 4rem 0;
    }
  }
}

.comments-wrapper {
  padding: 0 2rem 4rem 2rem;
  background: #fff;

  @media only screen and (min-width: $breakpoint-small) {
    padding: 0 2rem 4rem 2rem;
  }

  @media only screen and (min-width: $breakpoint-large) {
    padding: 0 4rem 4rem 4rem;
  }
}
</style>
